import { createContext, ReactNode, useContext, useState } from "react"

type MapProviderProps = {
  children: ReactNode
}

type Coordinates = {
  lat: number,
  lng: number
}

type MapContext = {
  map: Coordinates
  changeMap,
  locations,
  changeLocations,
  zoom: number
}

const MapContext = createContext({} as MapContext)

export function useMap() {
  return useContext(MapContext)
}
export function MapProvider({ children }: MapProviderProps) {
  const [map, setMap] = useState<Coordinates>({ lat: 38.7071, lng: -9.13549 })
  const [locations, setLocations] = useState<any>([])
  const [zoom, setZoom] = useState<number>(5)

  function changeMap(location) {
    setMap(location)
    setZoom(15)
  }
  function changeLocations(locations) {
    setLocations(locations)
  }

  return (
    <MapContext.Provider
      value={{
        map,
        changeMap,
        zoom,
        locations,
        changeLocations
      }}
    >
      {children}
    </MapContext.Provider>
  )
}
