import { XMarkIcon } from "@heroicons/react/24/outline"
import { useCheckout } from "../../context/CheckoutContext"

import './Summary.css'
import { useTranslation } from "react-i18next"

type ShoppingCartProps = {
  isOpen: boolean
}

export function Summary({ isOpen }: ShoppingCartProps) {
  const { t } = useTranslation()
  const { closeSummary, checkoutData } = useCheckout()

  return (
    <>
      {/* {isOpen && <div className="fixed top-0 bottom-0 w-full h-full bg-black bg-opacity-50 z-40"></div>} */}
      <div role="dialog" className={'fixed w-full h-full flex items-center justify-end top-0 z-50 ease-in-out duration-300 ' + (isOpen ? 'modal-open translate-x-0' : 'translate-x-full')}>
        <div className='w-full max-w-full h-full bg-white flex flex-col'>
          <div className='px-5 flex justify-between items-center py-4 bg-white'>
            <div className='font-medium text-xl flex gap-1'>{t('geral.summary.summary')}</div>
            <button id="close_sidebar_summary" title={t('geral.close')}><XMarkIcon className='h-5 w-5 flex-shrink-0' onClick={closeSummary} ></XMarkIcon></button>
          </div>
          <div className='px-5 py-4 flex flex-col w-full h-full justify-between overflow-y-auto'>
            {<><div className='flex flex-col w-full'>
              <div className="flex flex-col">
                {checkoutData?.lines?.map((item, index) => (
                  <div key={index} className="flex py-3">
                    <div className="w-20 h-auto mr-3">
                      <img
                        src={item.photo}
                        alt={item.title}
                        className="max-h-[108px] w-full h-full object-cover object-center"
                      />
                    </div>
                    <div className="w-full flex items-start justify-between">
                      <div className="h-full flex flex-col justify-between mr-5">
                        <div className="font-medium">{item.title}</div>
                        <div className="text-sm">Listed by {item.FirstName}</div>
                        <div className="flex text-sm">
                          {item.options?.O1Child} | {item.options?.O2Child}
                        </div>
                        <div className="text-sm">{item.condition_description}</div>
                      </div>
                      <div className="flex" style={{ minWidth: 'fit-content' }}>
                        <div className="font-medium">{item.price}{item.currency_symb_code}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            </>
            }
            <div className='mt-4 w-full flex flex-col justify-end divide-y divide-gray-200'>
              {/* <div className="mb-3 w-full flex justify-between">
                <div>Delivery</div>
                <div className="font-medium">{checkoutData?.shipping}{checkoutData?.currency_symb_code}</div>
              </div> */}
              {/* {checkoutData?.address_shipping_id &&
                <div>
                  <div className="mb-3 w-full flex justify-between">
                    <div>Processing fee</div>
                    <div className="font-medium">{checkoutData?.processing_fee}{checkoutData?.currency_symb_code}</div>
                  </div>
                  <div className="mb-3 w-full flex justify-between">
                    <div>Delivery</div>
                    <div className="font-medium">{checkoutData?.shipping}{checkoutData?.currency_symb_code}</div>
                  </div>
                </div>} */}
              <div className='text-base font-bold flex justify-between items-center py-4'>
                <div>{t('geral.summary.subtotal')} </div>
                <div>{checkoutData?.subtotal}{checkoutData?.currency_symb_code}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
