import { createContext, ReactNode, useContext, useState } from "react"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { Summary } from "../components/Summary/Summary"

type CheckoutProviderProps = {
  children: ReactNode
}

type CheckoutContext = {
  openSummary: () => void
  closeSummary: () => void
  checkoutGuest: boolean
  changeCheckoutGuest
  checkoutData
  changeCheckoutData: (checkoutData: any) => void
  selectedStore: any
  changeSelectedStore
}

const CheckoutContext = createContext({} as CheckoutContext)

export function useCheckout() {
  return useContext(CheckoutContext)
}
export function CheckoutProvider({ children }: CheckoutProviderProps) {
  const [isSummaryOpen, setIsSummaryOpen] = useState(false)
  const [checkoutGuest, setCheckoutGuest] = useLocalStorage<any>("checkoutGuest", false)
  const [selectedStore, setSelectedStore] = useLocalStorage<any>("selectedStore", '')
  const [checkoutData, setCheckoutData] = useState<any>()

  const openSummary = () => { setIsSummaryOpen(true); document.getElementsByTagName('body')[0].classList.add('modal-open') }
  const closeSummary = () => { setIsSummaryOpen(false); document.getElementsByTagName('body')[0].classList.remove('modal-open') }
  function changeCheckoutGuest(checkoutGuest) {
    setCheckoutGuest(checkoutGuest)
  }
  function changeCheckoutData(checkoutData) {
    setCheckoutData(checkoutData)
  }
  function changeSelectedStore(store) {
    setSelectedStore(store)
  }

  return (
    <CheckoutContext.Provider
      value={{
        openSummary,
        closeSummary,
        checkoutGuest,
        changeCheckoutGuest,
        checkoutData,
        changeCheckoutData,
        selectedStore,
        changeSelectedStore
      }}
    >
      {children}
      <Summary isOpen={isSummaryOpen} />
    </CheckoutContext.Provider>
  )
}
