import { Link, useNavigate } from "react-router-dom";
import { XMarkIcon, HeartIcon, TrashIcon } from "@heroicons/react/24/outline"
import { useGeral } from "../../context/GeralContext";
import { useCheckout } from "../../context/CheckoutContext";
import { useShoppingCart } from "../../context/ShoppingCartContext"

import Countdown from "../Countdown/Countdown";

import './ShoppingCart.css';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function ShoppingCart({ isOpen }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { tokenUser, changeTokenUser } = useGeral()
  const { removeFromCart, removeFromCartCache, closeCart, cartItems, changeCartItems, cartQuantity, openLogin, errorToast } = useShoppingCart()
  const { checkoutData, changeCheckoutData, changeCheckoutGuest, checkoutGuest } = useCheckout()
  const [submittedGoToCheckout, setSubmittedGoToCheckout] = useState<boolean>(false)
  const initialValue = 0;
  const totalAll = (cartItems.reduce(
    (accumulator, currentValue) => accumulator + currentValue.price,
    initialValue
  )).toFixed(2)

  function removeItemFromCart(event, item) {
    event.preventDefault();
    if (tokenUser) {
      removeFromCart(item.id)
    } else {
      removeFromCartCache(item.listing_id)
    }
  }
  function goToCheckout() {
    if (!tokenUser) {
      openLogin()
    } else {
      if (checkoutGuest) {
        changeCheckoutGuest(false)
        changeTokenUser('')
        openLogin()
      } else {
        getCheckoutData()
      }
    }
  }
  function getCheckoutData() {
    setSubmittedGoToCheckout(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + tokenUser);

    var requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + '/checkout', requestOptions)
      .then(async response => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          return Promise.reject({ status: response.status, message: error });
        }
      })
      .then(result => {
        changeCheckoutData(result)
        navigate("/checkout")
        closeCart()
        setSubmittedGoToCheckout(false)
      })
      .catch(error => {
        errorToast(error)
        setSubmittedGoToCheckout(false)
      })
  }
  function addToWishlist(event, product) {
    event.preventDefault()
    if (!tokenUser || checkoutGuest) {
      openLogin();
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + tokenUser);
      myHeaders.append("Content-Type", "application/json");
      let raw = JSON.stringify({
        "child_id": product.product_child_id
      });

      var requestOptions: RequestInit = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + '/customers/wishlist', requestOptions)
        .then(async response => {
          if (response.ok) {
            return response.json();
          } else {
            var error = await response.text();
            return Promise.reject({ status: response.status, message: error });
          }
        })
        .then(result => {
          var wish = false
          if (result.find(p => product.product_child_id === p.product_child_id)) {
            wish = true
          }
          changeCartItems([...cartItems.map((listing) =>
            listing.product_child_id === product.product_child_id ? { ...listing, wishlisted: wish } : listing
          )]);
        })
        .catch(error => {
          errorToast(error)
        });
    }
  }
  function closeCartSidebar() {
    closeCart()
    if (window.location.pathname === '/checkout' || window.location.pathname === '/review') {
      if (cartItems.length !== checkoutData.lines.length) {
        getCheckoutData()
      }
    }
  }

  return (
    <>
      {isOpen && <div className="fixed top-0 bottom-0 w-full h-full bg-black bg-opacity-50 z-40"></div>}
      <div role="dialog" className={'fixed w-full h-full flex items-center justify-between top-0 z-50 ease-in-out duration-300 ' + (isOpen ? 'modal-open translate-x-0' : 'translate-x-full')}>
        <div className="w-[calc(100%_-_450px)] h-full flex" onClick={closeCartSidebar}></div>
        <div className='w-[450px] max-w-full h-full bg-white flex flex-col'>
          <div className='border-block px-5 flex justify-between items-center py-4 bg-white'>
            <div className='font-medium text-xl flex gap-1'>{t('bag.shopping_bag')} {cartQuantity > 0 && <div>({cartQuantity})</div>}</div>
            <button id="close_sidebar_cart" title={t('geral.close')}><XMarkIcon className='h-5 w-5 flex-shrink-0' onClick={closeCartSidebar}></XMarkIcon></button>
          </div>
          {cartItems.length > 0 ?
            <>
              <div className='border-block px-5 py-4 flex flex-col w-full h-full justify-between overflow-y-auto'>
                <div className="flex flex-col">
                  {cartItems.map((item, index) => (
                    <div key={index} className="flex flex-col h-full justify-between gap-2 py-3">
                      {!item?.offer_id && item?.valid_listing === false && <div className="text-red-600 text-xs">{t('bag.product_unavailable')}</div>}
                      {item?.offer_id && item?.valid_offer === false && <div className="text-red-600 text-xs">{t('bag.offer_unavailable')}</div>}
                      {new Date() < new Date(item.offer_expire) && <Countdown date={item.offer_expire} simpleCount={1}></Countdown>}
                      <div className="w-full flex gap-2 no-underline">
                        <Link to={"/product/" + item.product_brand_id + "?variant=" + item.variant_id + "&listing=" + item.listing_id}
                          className={"w-20 no-underline " + ((!item?.offer_id && item?.valid_listing === false) || (item?.offer_id && item?.valid_offer === false) ? 'opacity-50 disabled' : 'opacity-100')}
                          onClick={closeCart}>
                          <img
                            src={item.photo}
                            alt={item.title}
                            className="h-full w-full object-contain object-center"
                          />
                        </Link>
                        <div className="w-full flex flex-col items-start justify-between">
                          <div className="w-full h-full flex flex-row justify-between gap-3">
                            <div className={"h-full flex flex-col justify-between text-sm " + ((!item?.offer_id && item?.valid_listing === false) || (item?.offer_id && item?.valid_offer === false) ? 'opacity-50' : 'opacity-100')}>
                              <Link to={"/product/" + item.product_brand_id + "?variant=" + item.variant_id + "&listing=" + item.listing_id}
                                className={"font-medium no-underline " + ((!item?.offer_id && item?.valid_listing === false) || (item?.offer_id && item?.valid_offer === false) ? 'disabled' : '')} onClick={closeCart}>{item.title}</Link>
                              <div className="inline-block">
                                <span className="mr-1">Listed by {item.customer_name}</span>
                                {Number(item.CustomerID) == Number(localStorage.getItem('customerSample')) && <span className="text-base material-symbols-outlined">verified</span>}
                              </div>
                              <div className="flex">
                                {item.options?.O1Child} | {item.options?.O2Child}
                              </div>
                              <div className="text-sm">{item.condition_description}</div>
                            </div>
                            <div className="h-full flex flex-col justify-between items-center whitespace-nowrap font-medium">
                              <div className="flex gap-2">
                                <button id={"add_whislist_" + item.listing_id} title={t('geral.close')} className="group flex gap-1 items-center cursor-pointer" onClick={(e) => addToWishlist(e, item)}
                                  disabled={(!item?.offer_id && item?.valid_listing === false) || (item?.offer_id && item?.valid_offer === false)}>
                                  <HeartIcon className={'h-5 w-5 flex-shrink-0 hover:fill-[var(--primary-color)] hover:stroke-[var(--primary-color)] ' + (item.wishlisted ? 'fill-[var(--bg-cart-wishlist-count)] stroke-[var(--bg-cart-wishlist-count)] hover:fill-[var(--bg-cart-wishlist-count)]' : '')} title={item.wishlisted ? "Remove from wishlist" : "Add to wishlist"}></HeartIcon>
                                </button>
                                <button id={"delete_product_" + item.listing_id} title={t('geral.remove')} className='group flex gap-1 items-center' onClick={(e) => removeItemFromCart(e, item)}>
                                  <TrashIcon className='h-5 w-5 flex-shrink-0 hover:fill-[var(--primary-color)]' title={t('geral.remove')}></TrashIcon>
                                </button>
                              </div>
                              <div>{item.price}{item.currency_symb_code}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='border-block px-5 py-4 w-full flex flex-col justify-end z-40'>
                <div className='flex justify-between items-center py-4 z-40'>
                  <div className="flex items-center gap-1">
                    <div className="text-2xl font-bold">{t('bag.total')}</div>
                    <div className="mt-2 text-xs font-normal text-gray-500">{t('bag.taxes_not_included')}</div>
                  </div>
                  {/* {checkoutData?.total ? <div className="text-2xl font-bold">{checkoutData?.subtotal}{checkoutData?.currency_symb_code}</div> : */}
                  <div className="text-2xl font-bold">{totalAll}€</div>
                </div>
                <div className="flex gap-2">
                  <button id="continue_shopping" className='hidden sm:flex w-full secundary-button justify-center font-medium mr-3' onClick={closeCartSidebar}>{t('bag.continue_shopping')}</button>
                  <button id="go_to_checkout" className='w-full primary-button font-medium' onClick={() => goToCheckout()} disabled={submittedGoToCheckout}>
                    {submittedGoToCheckout ? <svg aria-hidden="true" className="inline w-4 h-4 text-gray-200 animate-spin fill-[#14a76c]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg> : t('bag.go_to_checkout')}
                  </button>
                </div>
              </div>
            </>
            :
            <div className='px-5 py-4 flex flex-col w-full h-full items-center justify-center overflow-y-auto'>
              <div className="font-medium text-l">{t('bag.empty_bag')}</div>
              <div className="py-4 w-full flex justify-center">
                <Link to={"/all"} onClick={closeCart}>
                  <button id="start_shopping" className='primary-button font-medium' style={{ maxWidth: '300px' }}>{t('bag.start_shopping')}</button>
                </Link>
              </div>
            </div>}
        </div>
      </div >
    </>
  )
}
