import { Suspense, lazy, useEffect, useState, useTransition } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ShoppingCartProvider } from './context/ShoppingCartContext';
import { GeralProvider } from './context/GeralContext';
import { CheckoutProvider } from './context/CheckoutContext';
import { MapProvider } from './context/MapContext';

import './App.css';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from './hooks/useLocalStorage';
import { initJsStore } from './jsstore_con';

const Home = lazy(() => import('./pages/Home/Home'));
const Shopping = lazy(() => import('./pages/Shopping/Shopping'));
const ShoppingResale = lazy(() => import('./pages/Shopping/PagesShopping/ShoppingResale'));
const ShoppingBeautifulLosers = lazy(() => import('./pages/Shopping/PagesShopping/ShoppingBeautifulLosers'));
const Sell = lazy(() => import('./pages/Shopping/PagesShopping/Sell'));
const About = lazy(() => import('./pages/About/About'));
const Product = lazy(() => import('./pages/Product/Product'));
const Vendor = lazy(() => import('./pages/Vendor/Vendor'));
const Checkout = lazy(() => import('./pages/Checkout/Checkout'));
const User = lazy(() => import('./pages/User/User'));
const Listing = lazy(() => import('./pages/User/Listing'));
const ListingsID = lazy(() => import('./pages/User/ListingID'));
const Review = lazy(() => import('./pages/Review/Review'));
const Payment = lazy(() => import('./pages/Payment/Payment'));
const Offers = lazy(() => import('./pages/User/Offers'));
const UserDetails = lazy(() => import('./pages/User/UserDetails'));
const FindItem2Hand = lazy(() => import('./pages/User/FindItem2Hand'));
const SubmitItem2Hand = lazy(() => import('./pages/User/SubmitItem2Hand'));
const PaymentSucess = lazy(() => import('./pages/Payment/SucessPayment'));
const Orders = lazy(() => import('./pages/User/Orders'));
const OrdersID = lazy(() => import('./pages/User/OrdersID'));
const Wallet = lazy(() => import('./pages/User/Wallet'));
const Onboarding = lazy(() => import('./pages/User/Onboarding'));
const PrivacyPolicy = lazy(() => import('./pages/LegalPages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./pages/LegalPages/TermsAndConditions'));
const FAQs = lazy(() => import('./pages/LegalPages/FAQs'));
const ShippingAndDelivery = lazy(() => import('./pages/LegalPages/ShippingAndDelivery'));
const ResetPassword = lazy(() => import('./pages/User/ResetPassword'));
const OrderReview = lazy(() => import('./pages/User/OrderReview'));
const Search = lazy(() => import('./pages/Search/Search'));

function App() {
  const { i18n } = useTranslation();
  const [customerSample, setCustomerSample] = useLocalStorage<any>("customerSample", null)
  const [brandDetails, setBrandDetails] = useLocalStorage<any>("brandDetails", {
    "2hand_logo": null,
    "mini_logo": null,
    "image_sidebar": null,
    "main_domain": null,
    "return_center_link": null,
    "2hand_size_guide": null,
    "main_logo": null,
    "2hand_sidebar_image_vertical": null,
    "link_socials": [],
    "collections": { collections: [], collections_sample: [] }
  })

  useEffect(() => {
    if (Boolean(Number(process.env.REACT_APP_AUTO_TRANSLATE))) {
      const lng = navigator.language;
      i18n.changeLanguage(lng)
    }
    initJsStore();
    getBrandDetails();
  }, [])

  async function getBrandDetails() {
    var myHeaders = new Headers();

    var requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    await fetch(process.env.REACT_APP_URL + '/brands/details?id_brand=' + process.env.REACT_APP_ID_BRAND, requestOptions)
      .then(async response => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          return Promise.reject({ status: response.status, message: error });
        }
      })
      .then(result => {
        setBrandDetails(result)
        setCustomerSample(result.customerID ? result.customerID : null)
      })
  }

  return (
    <div className='relative flex flex-col h-full'>
      <GeralProvider>
        <CheckoutProvider>
          <ShoppingCartProvider brandDetails={brandDetails}>
            <MapProvider>
              <Header brandDetails={brandDetails}></Header>
              <ToastContainer position="top-center" autoClose={1500} />
              <Suspense fallback={<div className='w-full h-[calc(100vh_-_5rem)] bg-white'></div>}>
                <Routes>
                  <Route path="/" element={<Home brandDetails={brandDetails} />} />
                  <Route path="/all" element={<Shopping gen={''} brandDetails={brandDetails} />} />
                  <Route path="/women" element={<Shopping gen={'women'} brandDetails={brandDetails} />} />
                  <Route path="/men" element={<Shopping gen={'men'} brandDetails={brandDetails} />} />
                  <Route path="/clothing" element={<Shopping cat={['6']} brandDetails={brandDetails} />} />
                  <Route path="/accessories" element={<Shopping cat={['7', '11']} brandDetails={brandDetails} />} />
                  <Route path="/shop-pre-loved" element={process.env.REACT_APP_THEME == 'theme2' ? (<Shopping col={brandDetails?.collections?.collections} brandDetails={brandDetails} />) : (<Navigate replace to={"/"} />)} />
                  <Route path="/shop-beautiful-losers" element={process.env.REACT_APP_THEME == 'theme2' ? (<Shopping col={brandDetails?.collections?.collections_sample} brandDetails={brandDetails} />) : (<Navigate replace to={"/"} />)} />
                  <Route path="/shop" element={process.env.REACT_APP_THEME == 'theme2' ? (<ShoppingResale brandDetails={brandDetails} />) : (<Navigate replace to={"/"} />)} />
                  <Route path="/sell" element={process.env.REACT_APP_THEME == 'theme2' ? (<Sell brandDetails={brandDetails} />) : (<Navigate replace to={"/"} />)} />
                  <Route path="/beautiful-losers" element={process.env.REACT_APP_THEME == 'theme2' ? (<ShoppingBeautifulLosers brandDetails={brandDetails} />) : (<Navigate replace to={"/"} />)} />
                  <Route path="/about" element={<About brandDetails={brandDetails} />} />
                  <Route path="/product/:id" element={<Product brandDetails={brandDetails} />} />
                  <Route path="/vendor/:id" element={<Vendor />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/review" element={<Review />} />
                  <Route path="/payment" element={<Payment />} />
                  <Route path="/payment-sucess" element={<PaymentSucess />} />
                  <Route path="/user" element={<User />} >
                    <Route index element={<UserDetails />} />
                    <Route path="/user/offers" element={<Offers />} />
                    <Route path="/user/listings" element={<Listing brandDetails={brandDetails} />} />
                    <Route path="/user/listings/:id" element={<ListingsID />} />
                    <Route path="/user/orders" element={<Orders />} />
                    <Route path="/user/orders/:id" element={<OrdersID />} />
                    <Route path="/user/wallet" element={<Wallet />} />
                    <Route path="/user/onboarding" element={<Onboarding />} />
                    <Route path="/user/submit-item-2hand" element={<FindItem2Hand />} />
                    <Route path="/user/submit-item-2hand/:id" element={<SubmitItem2Hand />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Route>
                  <Route path="/privacy-policy" element={<PrivacyPolicy brandDetails={brandDetails} />} />
                  <Route path="/terms-and-conditions" element={<TermsAndConditions brandDetails={brandDetails} />} />
                  <Route path="/shipping" element={<ShippingAndDelivery brandDetails={brandDetails} />} />
                  <Route path="/faqs" element={<FAQs brandDetails={brandDetails} />} />
                  <Route path="/reset-password/:id" element={<ResetPassword />} />
                  <Route path="/review-order/:id" element={<OrderReview />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </Suspense>
              <Footer brandDetails={brandDetails}></Footer>
            </MapProvider>
          </ShoppingCartProvider>
        </CheckoutProvider>
      </GeralProvider>
    </div>
  );
}

export default App;
