import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { GoogleLogin } from "@react-oauth/google"
import { XMarkIcon, EyeIcon, EyeSlashIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useShoppingCart } from "../../context/ShoppingCartContext"
import { useGeral } from "../../context/GeralContext"

import './Login.css'
import { useCheckout } from "../../context/CheckoutContext"
import { Trans, useTranslation } from "react-i18next"

type ShoppingCartProps = {
  isOpen: boolean,
  brandDetails: any
}

type User = {
  name?: string,
  email: string,
  password: string
}
const userEmpty = { name: '', email: '', password: '' }

export function Login({ isOpen, brandDetails }: ShoppingCartProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isValidEmail, isValidPassword, changeTokenUser, changeUserNotifications } = useGeral()
  const { closeLogin, closeCart, isCartOpen, isLoginOpen, changeCartItems, cartItems, errorToast, changeUserDataByToken } = useShoppingCart()
  const { changeCheckoutGuest, changeSelectedStore } = useCheckout()
  const [signInTab, setSignInTab] = useState(true)
  const [newTab, setNewTab] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [user, setUser] = useState<User>(userEmpty)
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [submittedUser, setSubmittedUser] = useState<boolean>(false)
  const [submittedLogin, setSubmittedLogin] = useState<boolean>(false)
  const [submittedPassword, setSubmittedPassword] = useState<boolean>(false)
  const [successSubmittedPassword, setSuccessSubmittedPassword] = useState<boolean>(false)
  const [errorLogin, setErrorLogin] = useState<boolean>(false)
  const [errorRegister, setErrorRegister] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<any>('')

  useEffect(() => {
    if (isLoginOpen) {
      setSignInTab(true)
      setNewTab(false)
      setUser({ name: '', email: '', password: '' })
      setConfirmPassword('')
      setShowPassword(false)
      setShowConfirmPassword(false)
      setSubmittedUser(false)
      setSubmittedLogin(false)
      setErrorLogin(false)
      setErrorRegister(false)
      changeSelectedStore(null)
    }
  }, [isLoginOpen])
  const responseMessage = (response) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      code: response.credential,
      cart: cartItems.map(cartItem => ({ listing_id: cartItem.listing_id })),
      id_brand: process.env.REACT_APP_ID_BRAND
    });

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + '/customers/google', requestOptions)
      .then(response => response.json())
      .then(result => {
        changeCheckoutGuest(false)
        changeTokenUser(result.Customer.token)
        changeCartItems(result.Customer.Cart)
        changeUserNotifications(result.Customer.Notifications)
        changeUserDataByToken(result.Customer.token)
        closeLoginSideBar()
      })
  };

  function createAccount(event?) {
    event?.preventDefault()
    setSubmittedUser(true)
    setErrorRegister(false)
    if (user.name && user.email && isValidEmail(user.email) && user.password && isValidPassword(user.password) && confirmPassword && isValidConfirmPassword() && (!event || (event && event.key === "Enter"))) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        first_name: user.name.split(' ')[0],
        last_name: user.name.split(' ').pop(),
        EmailAddress: user.email,
        password: user.password,
        cart: cartItems.map(cartItem => ({ listing_id: cartItem.listing_id })),
        id_brand: Number(process.env.REACT_APP_ID_BRAND)
      });

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + '/customers/signin', requestOptions)
        .then(async response => {
          if (response.ok) {
            return response.json();
          } else {
            var error = await response.text();
            error = error.slice(1, error.length - 1)
            return Promise.reject({ status: response.status, message: error });
          }
        })
        .then(result => {
          changeCheckoutGuest(false)
          changeTokenUser(result.Customer.token)
          changeCartItems(result.Customer.Cart)
          changeUserNotifications(0)
          changeUserDataByToken(result.Customer.token)
          closeLoginSideBar()
          setUser({ name: '', email: '', password: '' })
          setSubmittedUser(false)
          setErrorRegister(false)
        })
        .catch(error => {
          setSubmittedUser(false)
          setErrorRegister(true)
          changeTokenUser("")
          setErrorMessage(error.message.toString())
        });
    } else {
      setErrorRegister(true)
      setSubmittedUser(false)
    }
  }
  function logIn(event?) {
    event?.preventDefault()
    setSubmittedLogin(true)
    setErrorLogin(false)
    if (user.email && isValidEmail(user.email) && user.password && (!event || (event && event.key === "Enter"))) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        email: user.email,
        password: user.password,
        cart: cartItems.map(cartItem => ({ listing_id: cartItem.listing_id })),
        id_brand: Number(process.env.REACT_APP_ID_BRAND)
      });

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + '/customers/login', requestOptions)
        .then(async response => {
          if (response.ok) {
            return response.json();
          } else {
            var error = await response.text();
            return Promise.reject({ status: response.status, message: error });
          }
        })
        .then(result => {
          changeCheckoutGuest(false)
          changeTokenUser(result.Customer.Token)
          changeCartItems(result.Customer.Cart)
          changeUserNotifications(result.Customer.Notifications)
          changeUserDataByToken(result.Customer.Token)
          closeLoginSideBar()
          setUser({ name: '', email: '', password: '' })
          setSubmittedLogin(false)
          setErrorLogin(false)
        })
        .catch(error => {
          setErrorLogin(true)
          changeTokenUser("")
          setSubmittedLogin(false)
        });
    } else {
      setErrorLogin(true)
      setSubmittedLogin(false)
    }
  }
  function isValidConfirmPassword() {
    return user.password === confirmPassword
  }
  function closeAllSidebars() {
    closeLoginSideBar()
    closeCart()
    setForgotPassword(false)
  }
  function closeLoginSideBar() {
    closeLogin()
    setSubmittedPassword(false)
    setSuccessSubmittedPassword(false)
    setForgotPassword(false)
  }
  function resetPassword() {
    setSubmittedPassword(true)
    if (user.email && isValidEmail(user.email)) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        "id_brand": Number(process.env.REACT_APP_ID_BRAND),
        "email": user.email
      });

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + '/customers/passwordrecovery', requestOptions)
        .then(async response => {
          if (response.ok) {
            return response.json();
          } else {
            var error = await response.text();
            return Promise.reject({ status: response.status, message: error });
          }
        })
        .then((result: any) => {
          setSuccessSubmittedPassword(true)
          setSubmittedPassword(false)
        })
        .catch(error => {
          errorToast(error)
        })
    } else {
      setTimeout(() => {
        setSubmittedPassword(false)
      }, 1000)
    }
  }
  function getCheckoutData() {
    closeCart()
    closeLogin()
    changeCheckoutGuest(true)
    navigate('/checkout')
  }

  return (
    <>
      {!isCartOpen && isOpen && <div className="fixed top-0 bottom-0 w-full h-full bg-black bg-opacity-50 z-40"></div>}
      <div role="dialog" className={'fixed w-full h-full flex items-center justify-end top-0 z-50 ease-in-out duration-300 ' + (isOpen ? 'modal-open translate-x-0' : 'translate-x-full')}>
        <div className="w-[calc(100%_-_450px)] h-full flex" onClick={closeAllSidebars}></div>
        <div className='w-[450px] max-w-full h-full bg-white flex flex-col'>
          <div className='px-5 flex justify-between items-center py-4 bg-white'>
            {!forgotPassword ? <div className='font-medium text-xl flex gap-1'></div> :
              successSubmittedPassword ? <div className='font-medium text-xl flex gap-1'>{t('login.check_inbox')}</div> :
                <div className='font-medium text-xl flex gap-1'>{t('login.forgot_password')}</div>}
            <button id="close_sidebar_login" title={t('geral.close')}><XMarkIcon className='h-5 w-5 flex-shrink-0' onClick={closeLoginSideBar} ></XMarkIcon></button>
          </div>
          <div className='px-5 py-4 flex flex-col w-full h-full justify-between overflow-y-auto'>
            {!forgotPassword ?
              <div className="h-full flex flex-col divide-y divide-gray-200">
                <div className="flex gap-5">
                  <button className={"transition duration-100 ease-linear p-1 uppercase border-b-2 " + (signInTab ? "border-[var(--primary-color)]" : "border-transparent")} onClick={() => { setErrorLogin(false); setUser(userEmpty); setSignInTab(true); setNewTab(false) }}>{t('login.sign_in')}</button>
                  <button className={"transition duration-100 ease-linear p-1 uppercase border-b-2 " + (newTab ? "border-[var(--primary-color)]" : "border-transparent")} onClick={() => { setErrorRegister(false); setUser(userEmpty); setSignInTab(false); setNewTab(true) }}>{t('login.new_here')}</button>
                </div>
                {signInTab ? (<div className="flex flex-col h-full py-4">
                  <div className="hidden sm:flex mt-4 justify-center">
                    <GoogleLogin onSuccess={responseMessage} width="410px" text="continue_with" />
                  </div>
                  <div className="flex sm:hidden mt-4 justify-center">
                    <GoogleLogin onSuccess={responseMessage} width="335px" text="continue_with" />
                  </div>
                  <div className="relative flex py-5 items-center">
                    <div className="flex-grow border-t border-gray-200"></div>
                    <span className="flex-shrink mx-4 text-gray-200 uppercase">{t('login.or')}</span>
                    <div className="flex-grow border-t border-gray-200"></div>
                  </div>
                  <div className="h-full flex flex-col justify-between">
                    <form className="flex flex-col">
                      <div className="relative">
                        <input maxLength={50} autoComplete="true" className={"peer relative z-20 bg-transparent rounded-none border w-full py-3 px-3 focus:border-[var(--primary-color)] focus:outline-none " + (errorLogin && (!user.email || !isValidEmail(user.email)) ? 'border-red-600 focus:border-red-600' : 'border-gray-200')} id="email" type="email" value={user.email}
                          onChange={(e) => { setUser({ ...user, email: e.target.value }); setErrorLogin(false) }} onKeyDown={(e) => { if (e.key === "Enter") { logIn(e) } }}></input>
                        {!user.email && <label htmlFor="email" className="absolute z-10 top-0 flex items-center py-3 px-3 text-gray-400 transition duration-100 h-full peer-focus:h-auto peer-focus:z-30 peer-focus:-translate-y-2 peer-focus:translate-x-2 peer-focus:transition-all peer-focus:text-xs peer-focus:bg-white peer-focus:py-0 peer-focus:px-1 peer-focus:text-[var(--primary-color)]">{t('geral.form.email_address')}</label>}
                        {user.email && <label htmlFor="email" className="absolute top-0 flex items-center bg-white z-30 translate-x-2 -translate-y-2 text-gray-400 text-xs peer-focus:text-[var(--primary-color)] py-0 px-1">{t('geral.form.email_address')}</label>}
                        {errorLogin && !user.email && <span className='text-xs text-red-600'>{t('geral.form.no_email')}</span>}
                        {errorLogin && user.email && !isValidEmail(user.email) && <span className='text-xs text-red-600'>{t('geral.form.invalid_email')}</span>}
                      </div>
                      <div className="mt-4 relative">
                        <input maxLength={50} autoComplete="true" className={"peer relative z-20 bg-transparent rounded-none border w-full pr-10 py-3 px-3 focus:border-[var(--primary-color)] focus:outline-none " + (errorLogin && !user.password ? 'border-red-600 focus:border-red-600' : 'border-gray-200')} id="password" type={showPassword ? 'text' : 'password'} value={user.password}
                          onChange={(e) => { setUser({ ...user, password: e.target.value }); setErrorLogin(false) }} onKeyDown={(e) => { if (e.key === "Enter") { logIn(e) } }}></input>
                        {!user.password && <label htmlFor="password" className="absolute z-10 top-0 flex items-center py-3 px-3 text-gray-400 transition duration-100 h-full peer-focus:h-auto peer-focus:z-30 peer-focus:-translate-y-2 peer-focus:translate-x-2 peer-focus:transition-all peer-focus:text-xs peer-focus:bg-white peer-focus:py-0 peer-focus:px-1 peer-focus:text-[var(--primary-color)]">{t('geral.form.password')}</label>}
                        {user.password && <label htmlFor="password" className="absolute top-0 flex items-center bg-white z-30 translate-x-2 -translate-y-2 text-gray-400 text-xs peer-focus:text-[var(--primary-color)] py-0 px-1">{t('geral.form.password')}</label>}
                        {!showPassword && <div className="w-full absolute top-0 flex justify-end py-3 px-3"><EyeIcon onClick={() => setShowPassword(true)} className='z-20 h-6 w-6 flex-shrink-0'></EyeIcon></div>}
                        {showPassword && <div className="w-full absolute top-0 flex justify-end py-3 px-3"><EyeSlashIcon onClick={() => setShowPassword(false)} className='z-20 h-6 w-6 flex-shrink-0'></EyeSlashIcon></div>}
                        {errorLogin && !user.password && <span className='text-xs text-red-600'>{t('geral.form.no_password')}</span>}
                      </div>
                      {errorLogin && user.email && isValidEmail(user.email) && user.password && <span className="mt-2 p-3 flex items-center gap-1 text-xs text-red-600 bg-red-100">
                        <ExclamationCircleIcon className='h-5 w-5 flex-shrink-0' onClick={closeLoginSideBar} ></ExclamationCircleIcon>{t('geral.form.incorret_combination')}</span>}
                      {brandDetails?.main_domain !== null && <div className="mt-4 p-3 flex items-center gap-1 text-xs text-yellow-600 bg-yellow-100">
                        <ExclamationCircleIcon className='h-5 w-5 flex-shrink-0' ></ExclamationCircleIcon>
                        <div>
                        <Trans i18nKey="login.account_distinct" values={{ title: process.env.REACT_APP_TITLE, link: (new URL(brandDetails?.main_domain)).hostname }}>Please be aware that a <b className='lowercase'>{process.env.REACT_APP_TITLE}</b> account is distinct and independent from your main account on <a aria-label='store' href={brandDetails?.main_domain}>{(new URL(brandDetails?.main_domain)).hostname}</a>.</Trans>
                        </div>
                      </div>}
                      <button type="button" className="mt-2 w-min whitespace-nowrap appearance-none underline mt-3 flex justify-start text-sm" onClick={() => setForgotPassword(true)}>{t('login.forgot_password')}</button>
                      <button type="button" className='mt-5 w-full primary-button font-medium' onClick={() => logIn()} disabled={submittedLogin}>
                        {submittedLogin && !errorLogin ? <svg aria-hidden="true" className="inline w-4 h-4 text-gray-200 animate-spin fill-[#14a76c]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg> : t('login.sign_in')}
                      </button>
                      <button className="appearance-none underline mt-3 flex justify-center text-sm" onClick={() => { setSignInTab(false); setNewTab(true) }}>{t('login.create_new_account')}</button>
                    </form>
                    {cartItems.length > 0 && <div className="flex flex-col gap-3">
                      <span className="flex justify-center text-sm">{t('login.register_later')}</span>
                      <button className='no-underline text-center w-full border border-gray-200 hover:bg-gray-100 py-3 px-4 text-base font-medium mr-3' onClick={getCheckoutData}>{t('login.continue_guest')}</button>
                    </div>}
                  </div>
                </div>) : (
                  <div className="flex flex-col h-full py-4">
                    <div className="hidden sm:flex mt-4 justify-center">
                      <GoogleLogin onSuccess={responseMessage} width="410px" text="continue_with" />
                    </div>
                    <div className="flex sm:hidden mt-4 justify-center">
                      <GoogleLogin onSuccess={responseMessage} width="335px" text="continue_with" />
                    </div>
                    <div className="relative flex py-5 items-center">
                      <div className="flex-grow border-t border-gray-200"></div>
                      <span className="flex-shrink mx-4 text-gray-200">{t('login.or')}</span>
                      <div className="flex-grow border-t border-gray-200"></div>
                    </div>
                    <div className="h-full flex flex-col justify-between">
                      <form className="flex flex-col">
                        <div className="relative">
                          <input maxLength={50} autoComplete="true" className={"peer relative z-20 bg-transparent rounded-none border w-full py-3 px-3 focus:border-[var(--primary-color)] focus:outline-none " + (errorRegister && !user.name ? 'border-red-600 focus:border-red-600' : 'border-gray-200')} id="name" type="text" value={user.name}
                            onChange={(e) => { setUser({ ...user, name: e.target.value }); setErrorRegister(false) }} onKeyDown={(e) => { if (e.key === "Enter") { createAccount(e) } }}></input>
                          {!user.name && <label htmlFor="name" className="absolute z-10 top-0 flex items-center py-3 px-3 text-gray-400 transition duration-100 h-full peer-focus:h-auto peer-focus:z-30 peer-focus:-translate-y-2 peer-focus:translate-x-2 peer-focus:transition-all peer-focus:text-xs peer-focus:bg-white peer-focus:py-0 peer-focus:px-1 peer-focus:text-[var(--primary-color)]">{t('geral.form.name')}</label>}
                          {user.name && <label htmlFor="name" className="absolute top-0 flex items-center bg-white z-30 translate-x-2 -translate-y-2 text-gray-400 text-xs peer-focus:text-[var(--primary-color)] py-0 px-1">{t('geral.form.name')}</label>}
                          {errorRegister && !user.name && <span className='text-xs text-red-600'>{t('geral.form.no_name')}</span>}
                        </div>
                        <div className="mt-4 relative">
                          <input maxLength={50} autoComplete="true" className={"peer relative z-20 bg-transparent rounded-none border w-full py-3 px-3 focus:border-[var(--primary-color)] focus:outline-none " + (errorRegister && (!user.email || !isValidEmail(user.email)) ? 'border-red-600 focus:border-red-600' : 'border-gray-200')} id="email" type="email" value={user.email}
                            onChange={(e) => { setUser({ ...user, email: e.target.value }); setErrorRegister(false) }} onKeyDown={(e) => { if (e.key === "Enter") { createAccount(e) } }}></input>
                          {!user.email && <label htmlFor="email" className="absolute z-10 top-0 flex items-center py-3 px-3 text-gray-400 transition duration-100 h-full peer-focus:h-auto peer-focus:z-30 peer-focus:-translate-y-2 peer-focus:translate-x-2 peer-focus:transition-all peer-focus:text-xs peer-focus:bg-white peer-focus:py-0 peer-focus:px-1 peer-focus:text-[var(--primary-color)]">{t('geral.form.email_address')}</label>}
                          {user.email && <label htmlFor="email" className="absolute top-0 flex items-center bg-white z-30 translate-x-2 -translate-y-2 text-gray-400 text-xs peer-focus:text-[var(--primary-color)] py-0 px-1">{t('geral.form.email_address')}</label>}
                          {errorRegister && !user.email && <span className='text-xs text-red-600'>{t('geral.form.no_email')}</span>}
                          {errorRegister && user.email && !isValidEmail(user.email) && <span className='text-xs text-red-600'>{t('geral.form.invalid_email')}</span>}
                        </div>
                        <div className="mt-4 relative">
                          <input maxLength={50} autoComplete="true" className={"peer relative z-20 bg-transparent rounded-none border w-full pr-10 py-3 px-3 focus:border-[var(--primary-color)] focus:outline-none " + (errorRegister && (!user.password || (user.password && !isValidPassword(user.password))) ? 'border-red-600 focus:border-red-600' : 'border-gray-200')} id="password" type={showPassword ? 'text' : 'password'} value={user.password}
                            onChange={(e) => { setUser({ ...user, password: e.target.value }); setErrorRegister(false) }} onKeyDown={(e) => { if (e.key === "Enter") { createAccount(e) } }}></input>
                          {!user.password && <label htmlFor="password" className="absolute z-10 top-0 flex items-center py-3 px-3 text-gray-400 transition duration-100 h-full peer-focus:h-auto peer-focus:z-30 peer-focus:-translate-y-2 peer-focus:translate-x-2 peer-focus:transition-all peer-focus:text-xs peer-focus:bg-white peer-focus:py-0 peer-focus:px-1 peer-focus:text-[var(--primary-color)]">{t('geral.form.password')}</label>}
                          {user.password && <label htmlFor="password" className="absolute top-0 flex items-center bg-white z-30 translate-x-2 -translate-y-2 text-gray-400 text-xs peer-focus:text-[var(--primary-color)] py-0 px-1">{t('geral.form.password')}</label>}
                          {!showPassword && <div className="w-full absolute top-0 flex justify-end py-3 px-3"><EyeIcon onClick={() => setShowPassword(true)} className='z-20 h-6 w-6 flex-shrink-0'></EyeIcon></div>}
                          {showPassword && <div className="w-full absolute top-0 flex justify-end py-3 px-3"><EyeSlashIcon onClick={() => setShowPassword(false)} className='z-20 h-6 w-6 flex-shrink-0'></EyeSlashIcon></div>}
                          {errorRegister && !user.password && <span className='text-xs text-red-600'>{t('geral.form.no_password')}</span>}
                          {errorRegister && user.password && !isValidPassword(user.password) && <span className='text-xs text-red-600'>{t('geral.form.password_rules')}</span>}
                        </div>
                        <div className="mt-4 relative">
                          <input maxLength={50} className={"peer relative z-20 bg-transparent rounded-none border w-full pr-10 py-3 px-3 focus:border-[var(--primary-color)] focus:outline-none " + (errorRegister && (!confirmPassword || (confirmPassword && !isValidConfirmPassword())) ? 'border-red-600 focus:border-red-600' : 'border-gray-200')} id="confirm_password" type={showConfirmPassword ? 'text' : 'password'} value={confirmPassword}
                            onChange={(e) => { setConfirmPassword(e.target.value); setErrorRegister(false) }} onKeyDown={(e) => { if (e.key === "Enter") { createAccount(e) } }}></input>
                          {!confirmPassword && <label htmlFor="confirm_password" className="absolute z-10 top-0 flex items-center py-3 px-3 text-gray-400 transition duration-100 h-full peer-focus:h-auto peer-focus:z-30 peer-focus:-translate-y-2 peer-focus:translate-x-2 peer-focus:transition-all peer-focus:text-xs peer-focus:bg-white peer-focus:py-0 peer-focus:px-1 peer-focus:text-[var(--primary-color)]">{t('geral.form.confirm_password')}</label>}
                          {confirmPassword && <label htmlFor="confirm_password" className="absolute top-0 flex items-center bg-white z-30 translate-x-2 -translate-y-2 text-gray-400 text-xs peer-focus:text-[var(--primary-color)] py-0 px-1">{t('geral.form.confirm_password')}</label>}
                          {!showConfirmPassword && <div className="w-full absolute top-0 flex justify-end py-3 px-3"><EyeIcon onClick={() => setShowConfirmPassword(true)} className='z-20 h-6 w-6 flex-shrink-0'></EyeIcon></div>}
                          {showConfirmPassword && <div className="w-full absolute top-0 flex justify-end py-3 px-3"><EyeSlashIcon onClick={() => setShowConfirmPassword(false)} className='z-20 h-6 w-6 flex-shrink-0'></EyeSlashIcon></div>}
                          {errorRegister && !confirmPassword && <span className='text-xs text-red-600'>{t('geral.form.no_password')}</span>}
                          {errorRegister && confirmPassword && !isValidConfirmPassword() && <span className='text-xs text-red-600'>{t('geral.form.password_not_match')}</span>}
                        </div>
                        {errorRegister && isValidEmail(user.email) && isValidPassword(user.password) && isValidConfirmPassword() && <span className="mt-2 p-3 flex items-center gap-1 text-xs text-red-600 bg-red-100">
                          <ExclamationCircleIcon className='h-5 w-5 flex-shrink-0' onClick={closeLoginSideBar} ></ExclamationCircleIcon>{errorMessage}</span>}
                        <span className="mt-3 text-sm">By registering, you agree to our <Link to={"/terms-and-conditions"} onClick={closeLoginSideBar}>Terms & Conditions</Link>, <Link to={"/privacy-policy"} onClick={closeLoginSideBar}>Privacy and Cookie Policy</Link>.</span>
                        <button type="button" className='mt-5 w-full primary-button font-medium' onClick={() => createAccount()} disabled={submittedUser}>
                          {submittedUser && !errorRegister ? <svg aria-hidden="true" className="inline w-4 h-4 text-gray-200 animate-spin fill-[#14a76c]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                          </svg> : t('login.register')}
                        </button>
                      </form>
                      {cartItems.length > 0 && <div className="flex flex-col gap-3">
                        <span className="flex justify-center text-sm">{t('login.register_later')}</span>
                        <button className='no-underline text-center w-full border border-gray-200 hover:bg-gray-100 py-3 px-4 text-base font-medium mr-3' onClick={getCheckoutData}>{t('login.continue_guest')}</button>
                      </div>}
                    </div>
                  </div>
                )}
              </div> :
              successSubmittedPassword ?
                <div className="py-4 flex flex-col">
                  <div>{t('login.sent_email')}</div>
                  <Link to={"/"} className='mt-5 w-full primary-button font-medium flex justify-center items-center no-underline' onClick={() => closeLoginSideBar()}>Continue shopping</Link>
                </div> :
                <div className="py-4">
                  <div>{t('login.link_to_reset_password')}</div>
                  <div className="mt-4 relative">
                    <input maxLength={50} className={"peer relative z-20 bg-transparent rounded-none border w-full py-3 px-3 focus:border-[var(--primary-color)] focus:outline-none " + (submittedLogin && !user.email ? 'border-red-600 focus:border-red-600' : 'border-gray-200')} id="email" type="email" value={user.email}
                      onChange={(e) => setUser({ ...user, email: e.target.value })} onKeyDown={(e) => { if (e.key === "Enter") { logIn(e) } }}></input>
                    {!user.email && <label htmlFor="email" className="absolute z-10 top-0 flex items-center py-3 px-3 text-gray-400 transition duration-100 h-full peer-focus:h-auto peer-focus:z-30 peer-focus:-translate-y-2 peer-focus:translate-x-2 peer-focus:transition-all peer-focus:text-xs peer-focus:bg-white peer-focus:py-0 peer-focus:px-1 peer-focus:text-[var(--primary-color)]">{t('geral.form.email_address')}</label>}
                    {user.email && <label htmlFor="email" className="absolute top-0 flex items-center bg-white z-30 translate-x-2 -translate-y-2 text-gray-400 text-xs peer-focus:text-[var(--primary-color)] py-0 px-1">{t('geral.form.email_address')}</label>}
                    {submittedPassword && !user.email && <span className='text-xs text-red-600'>{t('geral.form.no_email')}</span>}
                    {submittedPassword && user.email && !isValidEmail(user.email) && <span className='text-xs text-red-600'>{t('geral.form.invalid_email')}</span>}
                  </div>
                  <div className="mt-5 flex flex-col gap-3">
                    <button type="button" className='primary-button w-full font-medium' onClick={resetPassword}>
                      {submittedPassword ? <svg aria-hidden="true" className="inline w-4 h-4 text-gray-200 animate-spin fill-[#14a76c]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                      </svg> : t('login.reset_password')}
                    </button>
                    <button type="button" className='w-full border border-gray-200 hover:bg-gray-100 py-3 px-4 text-base font-medium' onClick={() => setForgotPassword(false)}>{t('login.back_to_sign')}</button>
                  </div>
                </div>}
          </div>
        </div >
      </div >
    </>
  )
}

