
import * as JsStore from 'jsstore';

const getWorkerPath = () => {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line import/no-webpack-loader-syntax
        return require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.js");
    }
    else {
        // eslint-disable-next-line import/no-webpack-loader-syntax
        return require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.min.js");
    }
};

const workerPath = getWorkerPath().default;
export const connection = new JsStore.Connection(new Worker(workerPath));


export const dbname = '2handImages';
const createDatabase = () => {
    const tblStudent: JsStore.ITable = {
        name: 'Images',
        columns: {
            id: {
                primaryKey: true,
                autoIncrement: true
            },
            base64: {
                notNull: true,
                dataType: JsStore.DATA_TYPE.String
            }
        }
    };
    const dataBase: JsStore.IDataBase = {
        name: dbname,
        tables: [tblStudent]
    };
    return dataBase;
};
export const initJsStore = async () => {
    const database = createDatabase();

    const isDbCreated = await connection.initDb(database);
    if (isDbCreated === true) {
        console.log("db created");
    }
    else {
        console.log("db opened");
    }
};
export const InsertImages = async (base64: any) => {
    var photo = await connection.insert({
        into: "Images",
        values: [{
            base64: base64
        }],
        return: true
    })
    return photo;
}
export const GetAllImages = async () => {
    var results = await connection.select({
        from: "Images"
    });

    return results;
}
export const GetPhotoByID = async (id) => {
    var result = await connection.select({
        from: "Images",
        where: {
            id: id
        },
        limit: 1
    });
    return result[0];
}
export const GetLastInput = async () => {
    var results = await connection.select({
        from: "Images",
        order: {
            by: "id",
            type: "desc" //supprted sort type is - asc,desc
        },
        limit: 1
    });
    return results[0]
}