import { useEffect, useState } from "react";
import { ClockIcon } from '@heroicons/react/24/outline'
import { useTranslation } from "react-i18next";

export function Countdown({ date, simpleCount }) {
    const { t } = useTranslation()
    const [expiryTime, setExpiryTime] = useState<any>(date);
    const [countdownTime, setCountdownTime] = useState<any>(
        {
            countdownDays: '',
            countdownHours: '',
            countdownlMinutes: '',
            countdownSeconds: ''
        }
    );

    function updateCountdown() {
        const countdownDateTime = new Date(expiryTime).getTime();
        const currentTime = new Date().getTime();
        const remainingDayTime = countdownDateTime - currentTime;
        const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
        const totalHours = Math.floor((remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
        const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

        const runningCountdownTime = {
            countdownDays: totalDays.toString().padStart(2, "0"),
            countdownHours: totalHours.toString().padStart(2, "0"),
            countdownMinutes: totalMinutes.toString().padStart(2, "0"),
            countdownSeconds: totalSeconds.toString().padStart(2, "0")
        }

        setCountdownTime(runningCountdownTime);
        return remainingDayTime;
    }
    const countdownTimer = () => {
        const timeInterval = setInterval(() => {
            var remainingDayTime = updateCountdown()
            if (remainingDayTime < 0) {
                clearInterval(timeInterval)
                setExpiryTime(false)
            }
        }, 1000);
    }

    useEffect(() => {
        countdownTimer();
    }, [countdownTime]);

    useEffect(() => {
        var remainingDayTime = updateCountdown()
        if (remainingDayTime < 0) {
            setExpiryTime(false);
        }
    }, []);

    return (
        <>
            {simpleCount === 0 ? <div className="flex flex-row gap-2 whitespace-nowrap">
                <div className="flex gap-1 items-center">{countdownTime.countdownDays > 0 && <div>{countdownTime.countdownDays} {t('geral.days')}</div>} {countdownTime.countdownDays === 0 && countdownTime.countdownHours > 0 && <div>{countdownTime.countdownHours} {t('geral.hours_and')}</div>} {countdownTime.countdownMinutes} {t('geral.minutes')}</div>
            </div> :
                simpleCount === 1 ? <div className="flex flex-row gap-2 text-yellow-500">
                    <div className="inline-flex flex-wrap whitespace-nowrap gap-1 items-center"><ClockIcon className="w-4 h-4"></ClockIcon>{t('geral.expires_in')} {countdownTime.countdownDays > 0 && <div>{countdownTime.countdownDays}{t('geral.days')}</div>} {countdownTime.countdownHours}h{countdownTime.countdownMinutes}m</div>
                </div> :
                    <div className="flex flex-row gap-2">
                        <div className="flex flex-row items-end gap-1">
                            {countdownTime.countdownDays > 0 && <div className="w-[40px] h-[54px] flex flex-col items-center px-2 py-1 bg-gray-100 border">
                                <div className="text-lg font-medium">{countdownTime.countdownDays}</div>
                                <div className="text-xs">{t('geral.days')}</div>
                            </div>}
                            <div className="w-[40px] h-[54px] flex flex-col items-center px-2 py-1 bg-gray-100 border">
                                <div className="text-lg font-medium">{countdownTime.countdownHours}</div>
                                <div className="text-xs">hrs</div>
                            </div>
                            <div className="w-[40px] h-[54px] flex flex-col items-center px-2 py-1 bg-gray-100 border">
                                <div className="text-lg font-medium">{countdownTime.countdownMinutes}</div>
                                <div className="text-xs">min</div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )

}

export default Countdown;