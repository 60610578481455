import { createContext, ReactNode, useContext, useState } from "react"
import { useLocalStorage } from './../hooks/useLocalStorage';

type GeralProviderProps = {
  children: ReactNode
}

type GeralContext = {
  isValidEmail: (email: string) => boolean
  isValidPassword: (password: string) => boolean
  minVal: number
  maxVal: number
  changeMinVal: (value: number) => void
  changeMaxVal: (value: number) => void
  optionSelected: string
  changeOptionSelected: (value: string) => void
  priceChange
  changePriceChange: (value: boolean) => void
  getHeaderUserSideMenu: () => string,
  setHeaderUserSideMenu: (key: string) => void
  tokenUser
  changeTokenUser: (token: string) => void
  userNotifications
  changeUserNotifications: (notifications: number) => void
}

const GeralContext = createContext({} as GeralContext)

export function useGeral() {
  return useContext(GeralContext)
}
export function GeralProvider({ children }: GeralProviderProps) {
  const [tokenUser, setTokenUser] = useLocalStorage<string>("access-token-user", '')
  const [userNotifications, setUserNotifications] = useLocalStorage<number>("notifications-user", 0)
  const [minVal, setMinVal] = useState<number>(0)
  const [maxVal, setMaxVal] = useState<number>(0)
  const [optionSelected, setOptionSelected] = useState<string>("")
  const [priceChange, setPriceChange] = useState<boolean>(false)
  const [headerUserMenu, setHeaderUserMenu] = useState("");

  function changeTokenUser(token: string) {
    setTokenUser(token)
  }
  function changeUserNotifications(notifications: number) {
    setUserNotifications(notifications)
  }
  function changeMinVal(value: number) {
    setMinVal(value)
  }
  function changeMaxVal(value: number) {
    setMaxVal(value)
  }
  function changeOptionSelected(value: string) {
    setOptionSelected(value)
  }
  function changePriceChange(value: boolean) {
    setPriceChange(value)
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function isValidPassword(password) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.:;?@[\\\]_`{|}~])[A-Za-z\d!"#$%&'()*+,-.:;?@[\\\]_`{|}~]{8,30}$/.test(password);
  }
  const getHeaderUserSideMenu = () => {
    return headerUserMenu
  }
  const setHeaderUserSideMenu = (key: string) => {
    setHeaderUserMenu(key);
  }

  return (
    <GeralContext.Provider
      value={{
        isValidEmail,
        isValidPassword,
        getHeaderUserSideMenu,
        setHeaderUserSideMenu,
        minVal,
        maxVal,
        changeMinVal,
        changeMaxVal,
        optionSelected,
        changeOptionSelected,
        priceChange,
        changePriceChange,
        tokenUser,
        changeTokenUser,
        userNotifications,
        changeUserNotifications,
      }}
    >
      {children}
    </GeralContext.Provider>
  )
}
