import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { ShoppingCart } from "../components/ShoppingCart/ShoppingCart"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { Login } from "../components/Login/Login"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGeral } from "./GeralContext";
import { useNavigate } from "react-router-dom";

type ShoppingCartProviderProps = {
  children: ReactNode,
  brandDetails: any
}

type ShoppingCartContext = {
  isCartOpen: boolean
  isLoginOpen: boolean
  openCart: () => void
  closeCart: () => void
  checkItemOnCart: (listing_id: number) => boolean
  addItemToCart: (listing_id: number, offer_id?: number) => void
  addItemToCartCache: (listing: any) => void
  removeFromCart: (shopping_bag_line_id: number) => void
  removeFromCartCache: (listing_id: number) => void
  cartQuantity: number
  cartItems: any[]
  changeCartItems: (cartItems: any[]) => void
  itemAddedToCart: boolean
  selectedSize: any
  changeSelectedSize: (size: any) => void
  selectedListingID: any
  changeSelectedListingID: (listing_id: any) => void
  openLogin: () => void
  closeLogin: () => void
  logOut: () => void
  errorToast: (error: any) => void
  userData
  changeUserData: (userData: any) => void
  changeUserDataByToken: (token: any) => void
}

const ShoppingCartContext = createContext({} as ShoppingCartContext)

export function useShoppingCart() {
  return useContext(ShoppingCartContext)
}
export function ShoppingCartProvider({ children, brandDetails }: ShoppingCartProviderProps) {
  const navigate = useNavigate();
  const { tokenUser, changeTokenUser, changeUserNotifications } = useGeral()
  const [userData, setUserData] = useLocalStorage<any>("user", null)
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [isLoginOpen, setIsLoginOpen] = useState(false)
  const [selectedSize, setSelectedSize] = useLocalStorage<any>("selected-size", null)
  const [selectedListingID, setSelectedListingID] = useLocalStorage<any>("selected-listing", null)
  const [itemAddedToCart, setItemAddedToCart] = useState(false)
  const [cartItems, setCartItems] = useLocalStorage<any[]>("shopping-cart", [])
  const cartQuantity = cartItems.reduce((quantity) => 1 + quantity, 0)

  useEffect(() => {
    if (isCartOpen && tokenUser) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + tokenUser);

      var requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + '/cart', requestOptions)
        .then(async response => {
          if (response.ok) {
            return response.json();
          } else {
            var error = await response.text();
            return Promise.reject({ status: response.status, message: error });
          }
        })
        .then(result => {
          setCartItems(result)
        })
        .catch(error => {
          errorToast(error)
        })
    }
  }, [isCartOpen])

  const openCart = () => { setIsCartOpen(true); document.getElementsByTagName('body')[0].classList.add('modal-open') }
  const closeCart = () => { setIsCartOpen(false); document.getElementsByTagName('body')[0].classList.remove('modal-open'); }
  const openLogin = () => { setIsLoginOpen(true); document.getElementsByTagName('body')[0].classList.add('modal-open') }
  const closeLogin = () => { setIsLoginOpen(false); document.getElementsByTagName('body')[0].classList.remove('modal-open') }
  function changeSelectedSize(size: any) {
    setSelectedSize(size)
  }
  function changeSelectedListingID(listing_id: any) {
    setSelectedListingID(listing_id)
  }
  function checkItemOnCart(listing_id: number) {
    return cartItems.find(item => item.listing_id === listing_id) ? true : false
  }
  function changeCartItems(cartItems: any[]) {
    setCartItems([...cartItems])
  }
  function addItemToCartCache(listing) {
    setCartItems([...cartItems, listing])
    setItemAddedToCart(true)
    setTimeout(() => { setItemAddedToCart(false) }, 2000)
    openCart()
  }
  function addItemToCart(listing_id, offer_id?) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + tokenUser);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      "listing_id": listing_id,
      "offer_id": offer_id
    });

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + '/cart', requestOptions)
      .then(async response => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          return Promise.reject({ status: response.status, message: error });
        }
      })
      .then((result: any) => {
        setCartItems(result)
        setItemAddedToCart(true)
        setTimeout(() => { setItemAddedToCart(false) }, 2000)
        openCart()
      })
      .catch(error => {
        errorToast(error)
      })
  }
  function removeFromCartCache(listing_id) {
    if (cartItems.filter(item => item.listing_id !== listing_id).length === 0) {
      closeCart()
      if (window.location.pathname === '/checkout' || window.location.pathname === '/review') {
        window.location.reload()
      }
    }
    return setCartItems(cartItems.filter(item => item.listing_id !== listing_id))
  }
  function removeFromCart(shopping_bag_line_id) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + tokenUser);

    var requestOptions: RequestInit = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + '/cart?shopping_bag_line_id=' + shopping_bag_line_id, requestOptions)
      .then(async response => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          return Promise.reject({ status: response.status, message: error });
        }
      })
      .then(result => {
        setCartItems(result)
        if (result.length === 0) {
          closeCart()
          if (window.location.pathname === '/checkout' || window.location.pathname === '/review') {
            window.location.reload()
          }
        }
      })
      .catch(error => {
        errorToast(error)
      })
  }
  function logOut() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + tokenUser);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + '/customers/logout', requestOptions)
      .then(async response => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          return Promise.reject({ status: response.status, message: error });
        }
      })
      .then(result => {
        changeTokenUser("")
        changeUserNotifications(0)
        setCartItems([])
        changeUserData(null)
        navigate("/")
      })
      .catch(error => {
        changeTokenUser("")
        changeUserNotifications(0)
        setCartItems([])
        changeUserData(null)
        navigate("/")
      })
  }
  function errorToast(error) {
    if (error.status === 422 && error.message !== '""') {
      toast.error(error.message, { position: "top-center", hideProgressBar: false })
    } else if (error.status === 401) {
      // if (!toast.isActive("session-expired")) {
      //   toast.error("Session expired", { position: "top-center", hideProgressBar: false, toastId: "session-expired" })
      // }
      navigate("/")
      logOutWithoutToken()
    } else if (error.status === 500) {
      navigate("/")
    }
  }
  function logOutWithoutToken() {
    changeTokenUser("")
    changeUserNotifications(0)
    setCartItems([])
    changeUserData(null)
    navigate("/")
    openLogin()
  }
  function changeUserData(userData: any) {
    setUserData(userData)
  }
  function changeUserDataByToken(token: any) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + '/customers/storefront', requestOptions)
      .then(async response => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          return Promise.reject({ status: response.status, message: error });
        }
      })
      .then(response => {
        setUserData(response)
      })
      .catch(error => {
        errorToast(error)
      });
  }

  return (
    <>
      <ShoppingCartContext.Provider
        value={{
          checkItemOnCart,
          addItemToCart,
          addItemToCartCache,
          removeFromCart,
          removeFromCartCache,
          isCartOpen,
          isLoginOpen,
          openCart,
          closeCart,
          cartItems,
          changeCartItems,
          cartQuantity,
          itemAddedToCart,
          selectedSize,
          changeSelectedSize,
          selectedListingID,
          changeSelectedListingID,
          openLogin,
          closeLogin,
          logOut,
          errorToast,
          userData,
          changeUserData,
          changeUserDataByToken
        }}
      >
        {children}
        <ShoppingCart isOpen={isCartOpen} />
        <Login isOpen={isLoginOpen} brandDetails={brandDetails} />
      </ShoppingCartContext.Provider>
    </>
  )
}
